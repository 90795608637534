.login__container{
	background-color: #fff;
	border-radius: 0.3rem;
	width: 20rem;
	margin: auto;
	display: grid;
	padding: 1rem 1.5rem;
	gap: 1rem;
	margin-top: 5rem;
}

.login__title{
	margin: 0 auto;
	margin-bottom: 0.5rem;
	color: rgb(11, 120, 163);
}

.login__field{
	height: 2rem;
	padding: 0 0.5rem;
	background-color: rgb(239, 251, 255);
	border: none;
}

.login__field::placeholder{
	color: rgb(32, 136, 177);
}

.login__btn{
	border: none;
	background-color: rgb(11, 120, 163);
	color: white;
	padding: 0.4rem 0;
	border-radius: 0.2rem;
	cursor: pointer;
	margin: 0.5rem 0;
}

.login__btn:hover{
	background-color: rgb(1, 107, 150);
}