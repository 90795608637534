.response__create-btn-group{
	display: flex;
	gap: 0.5rem;
	margin-bottom: 1rem;
}

.response__create-btn{
	border: none;
	color: white;
	padding: 0.3rem 0.5rem;
	margin-top: 0.5rem;
	cursor: pointer;
	border-radius: 0.2rem;
}

.response__create-btn-download{
	background-color: #1F8A70;
}

.response__create-btn-download:hover{
	background-color: #0e745a;
}

.response__create-btn-generate{
	background-color: #006da8;
}

.response__create-btn-generate:hover{
	background-color: #005c8d;
}

.response__create-btn-save{
	background-color: #3e28c0;
}

.response__create-btn-save:hover{
	background-color: #1f08a1;
}