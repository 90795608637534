:root{
	--height: max-content;
	--width: 40rem;
	--space: 1rem;
}

.form__container{
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1rem;
}

.form__box{
	min-height: var(--height);
	width: var(--width);
	padding: var(--space);
	margin-bottom: 1rem;
	background-color: white;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
	border-radius: 0.3rem;
	cursor: grab;
}

.form__box:active{
	cursor: grabbing;
}

.form__box-topic-input{
	width: 100%;
	height: 2rem;
	margin-top: 0.5rem;
}

.form__header{
	display: flex;
	gap: var(--space);
}

.form__header-input{
	flex: auto;
	min-height: 2rem;
	padding-left: 0.5rem;
}

.form__header-select{
	flex: 1;
}

.form__answer{
	display: flex;
	flex-wrap: wrap;
	gap: var(--space);
	margin: 1rem 0;
}

.form__group-input{
	height: 1.5rem;
	width: 100%;
}

.form__group-textarea{
	width: 100%;
}

.form__group-option{
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 0.3rem;
	margin-top: 0.5rem;
	height: 2rem;
}

.form__group-option > input{
	padding-left: 0.5rem;
}


.form__group-btn-remove{
	border: none;
	background-color: #e43838;
	color: white;
	cursor: pointer;
}

.form__group-btn-remove:hover{
	background-color: #c51a1a;
}

.form__group-btn-add-option{
	border: none;
	background-color: #1F8A70;
	color: white;
	padding: 0.3rem 0.5rem;
	margin-top: 0.5rem;
	cursor: pointer;
	border-radius: 0.2rem;
}

.form__group-btn-add-option:hover{
	background-color: #0e745a;
}

.form__answer-input{
	min-width: 19rem;
	height: 1.5rem;
}

.form__answer-input > input{
	min-width: 16.2rem;
}

.form__box-container{
	display: flex;
	flex-direction: column;
	align-items: space-between;
	justify-content: space-between;
	height: var(--height);
}

.form__box-btn-group{
	display: flex;
	justify-content: flex-end;
	gap: 0.5rem;
}

.form__slider {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 1rem;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
	margin-bottom: 1rem;
}
  
.form__slider:hover {
	opacity: 1;
}
  
.form__slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	background: rgb(5, 82, 184);
	cursor: pointer;
}
  
.form__slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	background: rgb(5, 82, 184);
	cursor: pointer;
}

.form__slider-label{
	margin: 1rem auto;
	text-align: center;
	background-color: rgb(5, 82, 184);
	color: white;
	height: 2rem;
	width: 2rem;
	font-weight: bold;
	line-height: 2rem;
	border-radius: 50%;
}

.formcreate__range{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
	margin-top: 0.5rem;
	gap: 0.5rem;
	width: 100%;
}

.formcreate__range-label{
	display: grid;
	gap: 0.5rem;
}

.formcreate__range-label > input{
	height: 1.5rem;
	padding-left: 0.5rem;
}

.formcreate__btn{
	border: none;
	color: white;
	padding: 0.5rem 1rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.formcreate__btn-add{
	background-color: #009249;
}

.formcreate__btn-add:hover{
	background-color: #00743a;
}

.formcreate__btn-delete{
	background-color: #9e0000;
}

.formcreate__btn-delete:hover{
	background-color: #830101;
}

.formcreate__btn-save{
	background-color: #006da8;
}

.formcreate__btn-save:hover{
	background-color: #005c8d;
}

@media screen and (max-width: 600px) {
	.form__box{
		width: 90%;
	}

	.formcreate__range{
		display: block;
	}
}