/* response page css */

.response__title{
	text-align: center;
	color: rgb(11, 120, 163);
}

.response__table{
	width: 95%;
	margin: auto;
	border-collapse: collapse;
	border: 1px solid rgb(11, 120, 163);
}

.response__table-th, .response__table-td{
	border-right: 1px solid rgb(11, 120, 163);
	padding: 0.5rem;
}

.response__table-text-center{
	text-align: center;
}

.response__table-head{
	background-color: rgb(11, 120, 163);
	color: white;
}

.response__table-row:nth-child(odd){
	background-color: #fff;
}

.response__table-row:nth-child(even){
	background-color: rgba(207, 241, 255, 0.452);
}

.response__table-btn{
	margin: 0 0.1rem;
	padding: 0.2rem 0.5rem;
	border: none;
	color: white;
	border-radius: 0.3em;
	font-size: 0.75rem;
	line-height: 1rem;
	cursor: pointer;
}

.response__table-btn-response{
	background-color: #1a86aa;
}

.response__table-btn-response:hover{
	background-color: #006e92;
}

.response__table-btn-edit{
	background-color: #7345ca;
}

.response__table-btn-edit:hover{
	background-color: #5728ad;
}

.response__table-btn-view{
	background-color: #008F7A;
}

.response__table-btn-view:hover{
	background-color: #006d5d;
}


/* response details page css */
.response__btn-header{
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;
	padding: 0 0.5rem;
}

.response__btn-header-title{
	color: rgb(11, 120, 163);
	font-weight: bold;
}

.response__btn-header-download{
	background-color: #009249;
	color: white;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	margin: 1rem 0.5rem;
	cursor: pointer;
}

.response__btn-header-download:hover{
	background-color: #00793c;
}

.response__btn-header-download:disabled{
	background-color: gray;
	color: black;
}

.response-details__table{
	border: 1px solid rgb(11, 120, 163);
	border-collapse: collapse;
}

.response-details__table-head{
	position: sticky;
	top: 0;
	background-color: rgb(11, 120, 163);
	color: white;
	z-index: 10;
}

.response-details__table-th, .response-details__table-td{
	min-width: 15rem;
	/* border: 1px solid rgb(11, 120, 163); */
	border-right: 1px solid rgb(11, 120, 163);
	padding: 0.5rem;
}

.response-details__table-row:nth-child(odd){
	background-color: #fff;
}

.response-details__table-row:nth-child(even){
	background-color: rgba(209, 242, 255, 0.473);
}

.response-details__table-th:first-child, .response-details__table-td:first-child{
	min-width: 3rem;
	text-align: center;
}

@media screen and (max-width: 600px) {
	.response__table-btn{
		margin: 0.1rem;
	}
}