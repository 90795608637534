:root{
	--height: max-content;
	--width: 40rem;
	--space: 1rem;
	background-color: #ECEEF0;
}

.question__container{
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1rem;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif ;
	/* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
	/* font-family: Verdana, Geneva, Tahoma, sans-serif; */
	/* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
}

.question__box-topic{
	width: var(--width);
	padding: var(--space);
	margin-bottom: 1rem;
	background-color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	text-align: center;
	color: rgb(5, 82, 184);
	font-weight: bold;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.question__box{
	min-height: 5rem;
	width: var(--width);
	padding: var(--space);
	margin-bottom: 1rem;
	background-color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}

.answer-input{
	margin-top: 1rem;
	line-height: 1.5rem;
	height: 2rem;
	width: calc(var(--width) - 0.5rem);
	padding-left: 0.5rem;
	border: 0;
	border-bottom: 1px solid rgb(5, 82, 184);
	outline: none;
}

.answer-dropdown{
	margin-top: 0.5rem;
	width: var(--width);
	height: 2rem;
	border: 1px solid rgb(5, 82, 184);
	outline: none;
}

.answer-dropdown option{
	font-size: 1rem;
}

.option-input{
	margin-top: 1rem;
	height: 2rem;
	min-width: 20rem;
}

.options-input-group{
	margin-top: 1rem;
	display: grid;
	gap: 0.4rem;
}

.options-input{
	flex: 1;
	flex-grow: 0;
	min-width: max-content;
	min-height: 3rem;
	line-height: 3rem;
	display: flex;
	border: 1px solid rgb(215, 215, 215);
	/* border: 1px solid rgb(150, 172, 201); */
	border-radius: 0.3rem;
}

.options-input:hover{
	border: 1px solid rgb(5, 82, 184);
}

.options-input-label{
	width: 100%;
	padding-left: 0.5rem;
	cursor: pointer;
}

input:checked ~ label > div{
	color: rgb(5, 82, 184);
}

.range-input-group{
	display: flex;
	justify-content: center;
	gap: 0.4rem;
	margin-top: 1rem;
}

.range-input-options{
	display: flex;
}

.range-input-label{
	cursor: pointer;
}

.question-title{
	color: rgb(5, 82, 184);
}

.formview__btn-add{
	border: none;
	outline: none;
	color: white;
	padding: 0.5rem 1rem;
	border-radius: 0.2rem;
	cursor: pointer;
	background-color: #009249;
}

.formview__btn-add:hover{
	background-color: #006e37;
}

@media screen and (max-width: 600px) {
	.question__box-topic, .question__box, .answer-dropdown, .answer-input{
		width: 90%;
	}
}